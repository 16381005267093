export interface HashSettings {
    /**
     * Enable/Disable hash option
     */
    hash: boolean;
}

export const hashSettings: HashSettings = {
    hash: true,
};
